html,
body {
  font-family: 'Roboto', sans-serif;
}

.modal .modal-content {
  -webkit-box-shadow: 0 10px 50px -10px rgba(0, 0, 0, 0.3);
  box-shadow: 0 10px 50px -10px rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: 2px;
}

.modal .modal-content .modal-header {
  border-bottom: none;
  padding-bottom: 0 !important;
  padding-left: 30px;
  padding-right: 30px;
}

.modal .modal-content .modal-header .modal-title h5 {
  padding-bottom: 0;
}

.modal .modal-content .modal-body {
  padding-left: 30px;
  padding-right: 30px;
}

.fade2 {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all .2s linear;
  -o-transition: all .2s linear;
  transition: all .2s linear;
  display: block !important;
}

.fade2.show {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.modal-title {
  font-weight: 600;
}

.form-control {
  border-radius: 2px;
  border: 1px solid #cbcbcb;
}

.form-control:focus {
  outline: none;
  background-color: #fff;
  border-color: #cbcbcb;
  outline: 0;
  box-shadow: none;
}

.btn {
  border-radius: 2px;
  min-width: 100px;
}

.btn:hover,
.btn:active,
.btn:focus-visible {
  background-color: #b86712 !important;
  border-color: #b86712 !important;
  box-shadow: none !important
}

.btn-primary {
  color: #fff;
  background-color: #f67d00;
  border-color: #f67d00;
  border: 1px solid #f67d00;
}

.box-shadow {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.common-padding {
  padding: 20px;
}

.all-border {
  border: 1px solid #eee;
}

.left-border {
  border-left: 1px solid #eee;
}

.right-border {
  border-right: 1px solid #eee;
}

.top-border {
  border-top: 1px solid #eee;
}

.bottom-border {
  border-bottom: 1px solid #eee;
}

.sub-title {
  font-size: 17px !important;
}

.text-right {
  text-align: right !important;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters>.col,
.no-gutters>[class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}
