.flyOut {
    position: fixed;
    bottom: 20%;
    z-index: 10;
    right: 10px;
}

.flyOut ul {
    list-style: none;
    border: 1px solid #eee;
    text-align: center;
    padding: 0;
    margin: 0;
    display: block;
    border-bottom: 1px solid #eee;
    background: #fff;
    border-radius: 2px;
}

.flyOut ul li {
    display: block;
    border-bottom: 1px solid #eee;
    position: relative;
}

.flyOut ul li a {
    text-decoration: none;
    font-size: 15px;
    color: #777777;
    padding: 10px 20px;
    display: block;
}

.flyOut ul li a:hover {
    color: #f67d00;
}

.flyOut ul li:hover .flyOut-item {
    display: block;
}

.flyOut ul li .flyOut-item {
    color: #000;
    border: 1px solid #eee;
    position: absolute;
    z-index: 10;
    right: 55px;
    width: auto;
    min-width: 180px;
    top: -1px;
    padding: 10px 15px;
    display: none;
    background-color: #fff;
}

