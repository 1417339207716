.sticky-header {
    position: absolute;
    z-index: 100;
    left: 0;
    right: 0;
    top: 0;
}

.blur-bg {
    opacity: 1;
    visibility: visible;
    -webkit-backdrop-filter: blur(100px) !important;
    backdrop-filter: blur(100px) !important;
    background: rgba(254, 254, 254, 0.8) !important;
}

#navbarNav {
    flex-wrap: wrap;
}

.break {
    flex-basis: 100%;
    height: 0;
}

.header-contact-details {
    display: flex;
    list-style: none;
    flex-direction: row;
    margin: 0;
    margin-left: auto;
    padding: 0;

}

.header-contact-details li {
    display: inline-block;
    padding: 0 10px;
}

.header-contact-details li p {
    display: inline-block;
    /* font-weight: 600; */
    padding-left: 10px;
}

.header-contact-details li p i {
    margin-right: 5px;
}

.app-nav .navbar-nav {
    margin-left: auto;
}

.app-nav {
    background: #fff;
    margin: 0px auto 0;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    border-radius: 2px;
}

.header_area .navbar .nav-item.submenu {
    position: relative;
}

.header_area .navbar .nav-item {
    margin-right: 10px;
    /* min-width: 113px; */
}

.header_area .navbar .nav-item .nav-link {
    text-transform: uppercase;
    padding: 0px;
    display: inline-block;
    font-size: 12px;
    color: #222222;
}


.header_area .navbar .nav-item.submenu ul {
    border: none;
    padding: 0px;
    top: 55px;
    border-radius: 0px;
    box-shadow: none;
    margin: 0px;
    background: #fff;
    min-width: 200px;
    text-align: left;
    -webkit-transition: visibility 0.5s ease;
    -moz-transition: visibility 0.5s ease;
    -o-transition: visibility 0.5s ease;
    transition: visibility 0.5s ease;
}

.header_area .navbar .nav-item .nav-link {
    text-transform: uppercase;
    padding: 10px 20px;
    display: inline-block;
    font-size: 14px;
    color: #222222;
}

.header_area .nav-item:hover .nav-link,
.header_area .nav-item.active .nav-link {
    color: #f67d00 !important;
}

.submenu ul .nav-item .nav-link {
    line-height: 45px;
    padding: 0px 30px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    display: block;
    margin-right: 0px;
}

.header_area .navbar .nav-item.submenu ul .nav-item {
    display: block;
    float: none;
    margin-right: 0px;
    border-bottom: 1px solid #ededed;
    margin-left: 0px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.header_area .navbar .nav-item.submenu ul .nav-item:hover .nav-link {
    background: #f67d00;
    color: #fff !important;
}

.header_area .navbar .nav-item.submenu ul .nav-item .nav-link {
    line-height: 45px;
    padding: 0px 30px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    display: block;
    margin-right: 0px;
}

.header_area .navbar .nav-item .header-nav-btn {
    background-color: #f67d00;
    color: #fff;
    border: 1px solid #f67d00;
    border-radius: 2px;
}

.header_area .nav-item:hover .nav-link.header-nav-btn {
    color: #fff !important;
}

.header_area .navbar .nav-item .header-nav-btn .fa {
    margin-left: 10px;
}

.full-width {
    width: 100%;
    clear: both;
    float: left;
}

@media only screen and (max-width: 768px) {
    .header-contact-details {
        display: none !important;
    }

    .app-nav .navbar-nav {
        text-align: center;
    }

    .app-nav .navbar-nav>li {
        border-bottom: 1px solid#eee
    }
}