.testimonials i {
    color: #f67d00;
}

.testimonials .testimonials-container {
    margin: 5px;
    padding: 15px 20px;
    min-height: 395px;
}

.testimonials .testimonials-container img {
    width: 100%;
    border-radius: 100%;
    display: block;
    aspect-ratio: 4/4;
    margin-top: 6px;
    max-width: 120px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.testimonials .testimonials-container span {
    display: block;
    padding: 2px 10px;
    text-align: center;
}

.testimonials hr {
    border: 1px solid #c8c8c8;
}