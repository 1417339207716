.display-href {
    position: relative;
    text-decoration: none;
    display: block;
}

.display-href img {
    display: block;
    width: 100%;
    aspect-ratio: 4/3;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 10px;
}

.display-href-link {
    color: #222;
    padding: 8px 6px;
    display: block;
    text-decoration: none;
}

.display-href .overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #00000021;
    border-radius: 10px;
}