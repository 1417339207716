.sticky-header {
    position: relative;
}

.property-container .property-image {
    width: 100%;
    border-radius: 10px;
}

.property-container .property-details ul {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
}

.property-container .property-details ul li {
    display: inline-block;
    min-height: 40px;
    vertical-align: top;
}

.property-container .property-details ul li.li-icon {
    display: inline-block;
    background-color: #01010133;
    border-radius: 2px;
    color: #fff;
    width: 45px;
    height: 45px;
    text-align: center;
    margin-right: 10px;
}


.property-container .property-details span.prop-icon {
    opacity: 0.8;
    font-size: 15px;
    padding: 10px;
    display: block;
}

.property-container .property-details span.prop-label {
    font-weight: 600;
    display: block;
}

.property-container .property-details span.prop-value {
    display: block;
}

.img-fluid {
    aspect-ratio: 3/2;
}

.features .feature-icon {
    vertical-align: middle;
}

.features .feature-icon {
    background-color: #01010133;
    color: #fff;
    width: 32px;
    height: 32px;
    display: inline-block;
    text-align: center;
    border-radius: 2px;
}

.features .feature-icon {
    margin-right: 5px;
}

.features .feature-icon i {
    padding-top: 8px;
}