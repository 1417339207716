.OurProjects .project-content {
    margin: 10px 0;
    padding: 10px;
}

.OurProjects .image-link {
    position: relative;
    text-decoration: none;
    display: block;
}

.OurProjects .image-link img {
    display: block;
    width: 100%;
    aspect-ratio: 4/3;
    object-fit: cover;
    border-radius: 10px;
    margin: 0 0 10px 0;
}

.OurProjects .image-link .overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #00000021;
    border-radius: 10px;
}

.OurProjects h2.project-name {
    font-size: 17px;
    padding: 10px 5px;
    font-weight: 600;
}

.OurProjects ul {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0;
}

.OurProjects ul li {
    display: inline-block;
    padding: 10px;
}

.OurProjects ul li i {
    margin-right: 5px;
}

.OurProjects .view-more {
    display: block;
    margin: 10px;
}

i {
    margin-left: 5px;
    margin-right: 5px;
}