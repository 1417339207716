.display-Card i {
    position: relative;
    margin-right: 10px;
    font-size: 28px;
    color: #f67d00;
    display: inline-block;
}

.display-Card i span {
    position: absolute;
    font-size: 12px;
    color: #fff;
    left: 3px;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

@media only screen and (max-width: 500px) {

    /* .why-us .row div {
        margin-bottom: 10px;
    } */

}