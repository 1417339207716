@media only screen and (max-width: 600px) {
    .project-counter .row div {
        border-bottom: 1px solid #eee;
        padding-top: 10px;
        border-right: 0 !important;
        padding-bottom: 10px;
    }

    .project-counter .row div>h2.count {
        font-weight: 600;
    }

    .project-counter .row div:last-child {
        border: none !important;
    }

}