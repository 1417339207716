.video-banner {
    position: relative;
    height: 100vh;
}

.video-banner .video-bg {
    line-height: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.video-banner .video-bg img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.video-banner .video-container {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
    z-index: 2;
}

.video-banner .video-container .video {
    width: 100%;
    height: 100vh;
    position: relative;
    z-index: 1;
    margin: 0 auto;
}

.video-banner .video-container .video video {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.video-banner h1 {
    position: absolute;
    bottom: 20%;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    left: 0;
    z-index: 10;
    width: 90%;
    font-size: 100px;
    line-height: 1;
    color: #ede8e5;
    text-align: center;
    opacity: 1;
    margin: 0 5%;
    letter-spacing: 0;
    font-weight: 600;
    font-size: 70px;
}