.gallery-block .row {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    padding: 40px;
}

.gallery-block .heading h2 {
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
    font-size: 23px;
    font-weight: 900;
}

.gallery-block.compact-gallery .item {
    overflow: hidden;
    margin-bottom: 0;
    opacity: 1;
    margin-bottom: 10px;
}

.gallery-block.compact-gallery .item .image {
    transition: 0.8s ease;
}

.gallery-block.compact-gallery .item .info {
    position: relative;
    display: inline-block;
}

.gallery-block.compact-gallery .item .description {
    display: grid;
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    padding: 10px;
    font-size: 17px;
    line-height: 18px;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    opacity: 1;
    color: #fff;
    transition: 0.8s ease;
    text-align: center;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.39));
}

.gallery-block.compact-gallery .item .description .description-heading {
    font-size: 1em;
    font-weight: bold;
}

.gallery-block.compact-gallery .item .description .description-body {
    font-size: 0.8em;
    margin-top: 10px;
    font-weight: 300;
}

@media (min-width: 576px) {

    .gallery-block.compact-gallery .item .description {
        opacity: 0;
    }

    .gallery-block.compact-gallery .item a:hover .description {
        opacity: 1;
    }

    .gallery-block .zoom-on-hover:hover .image {
        transform: scale(1.3);
        opacity: 0.7;
    }
}