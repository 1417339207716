.footer {
    background-color: #f67d00;
    padding: 0;
}

.footer footer {
    background-color: #f67d00;
    padding: 1px 0 0 0;
}

.footer .copyright,
.footer .social-media {
    background-color: rgba(0, 0, 0, 0.2);
}

.footer hr {
    width: 60px;
    background-color: #7c4dff;
    height: 2px
}

.footer a {
    text-decoration: none;
}