.video-container {
    padding: 10px;
    border-radius: 10px;
}

.video-container a {
    position: relative;
}

.video-container a img {
    display: block;
    width: 100%;
    aspect-ratio: 4/3;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 10px;
}

.video-container a .overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #00000021;
    border-radius: 10px;
    z-index: 1;
}

.video-container a .play-button {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    z-index: 2;
    
}

.video-container a .play-button .play-bg {
    background-color: #f67d00bd;
    border-radius: 50%;
    padding: 11px 13px 11px 15px;
    text-align: center;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.video-container a:hover .play-bg {
    padding: 13px 15px 13px 17px;
}


@media only screen and (max-width: 600px) {

    .video-banner h1{font-size: 40px;}
}